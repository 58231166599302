import useLoloMember from '../hooks/use-lolo-member';
import { UserPermissions } from '../lib/authorization/roles';

/**
 * Can component based on https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/#Role-Based-Access-Control-Example-in-React-Apps
 * @param props
 */
const Can = (props: {
  no: () => React.ReactElement;
  perform: UserPermissions;
  data?: Record<string, unknown>;
  yes: () => React.ReactElement;
}): React.ReactElement => {
  const { member, isLoading, check, error } = useLoloMember();

  if (isLoading || !member) {
    // we are unable to determine permissions at this point, so just say no.
    return props.no();
  }

  return check(props.perform, props.data, member.developerMode)
    ? props.yes()
    : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
