/**
 * Anchor tags are valid in this file, the links in the header
 * do link to new pages. Href values are injected by nextjs.
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUser } from '@auth0/nextjs-auth0';
import { Box, Button, CheckSwitch } from '@deckee/deck-hand';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLoloMember from '../hooks/use-lolo-member';
import useSoundAlarm from '../hooks/use-sound-alarm';
import { toProperCase } from '../lib/string';
import {
  createLoginUrl,
  createLogOutUrl,
  createProfileUrl,
} from '../lib/url-helper';
import Can from './can';
import Logo from './logo';
import NavMenuDropdown from './nav-menu-dropdown';
import SoundPlayer from './sound-player';

const HEADER_HEIGHT = 66;

const StyledHeader = styled.header`
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  height: ${HEADER_HEIGHT}px;

  position: sticky;
  top: 0px;
  z-index: 9999999;
  box-shadow: -2px -8px 5px 5px black;
  overflow: visible;
`;

const Nav = styled.nav`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;

  a {
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
    font-family: ${({ theme }) => theme.typeface.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.336332px;
  }
`;

const UnorderedList = styled.ul`
  display: flex;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  align-items: center;
  height: ${HEADER_HEIGHT}px;
  line-height: ${HEADER_HEIGHT}px;
`;

interface HeaderButtonContainerProps
  extends HTMLAttributes<HTMLDataListElement> {
  selected?: boolean;
}

const HeaderButtonContainer = styled.li<HeaderButtonContainerProps>`
  margin-right: 2rem;
  align-self: center;
  height: ${HEADER_HEIGHT}px;
  line-height: ${HEADER_HEIGHT}px;
  border-bottom: ${({ theme, selected }) =>
    selected ? `5px solid ${theme.colors.primary}` : 'none'};

  a {
    font-weight: ${({ theme, selected }) => (selected ? 'bold' : 'normal')};
  }

  &:nth-child(2) {
    margin-left: 2rem;
  }

  &:nth-last-child(2) {
    margin-right: auto;
  }

  &:last-child {
    margin-right: 0;
  }
`;

// Function to request permission for notifications
function requestNotificationPermission() {
  navigator.permissions.query({ name: 'notifications' });
  if (Notification.permission !== 'granted') {
    Notification.requestPermission().then((permission) => {});
  }
}

// Function to show a notification with sound
function showNotification(title, message) {
  if (Notification.permission === 'granted') {
    new Notification(title, {
      body: message,
      silent: false,
      // @ts-ignore
      sound: 'SAR-Alarm.mp3',
    });
  } else {
    alert('Please enable notifications first.');
  }
}

const Header = (): React.ReactElement => {
  const router = useRouter();
  const { user, isLoading } = useUser();
  const { member } = useLoloMember();
  const { soundAlarm } = useSoundAlarm();
  const [alarmEnabled, setAlarmEnabled] = useState(false);

  const handleToggleEnableSound = () => {
    if (!alarmEnabled) {
      requestNotificationPermission();
    }
    setAlarmEnabled(!alarmEnabled);
  };
  const handleNewLogOnBtnClick = () => {
    router.push({
      pathname: '/trips/create',
      query: {},
    });
  };

  useEffect(() => {
    if (alarmEnabled && soundAlarm) {
      showNotification(
        'A trip has transitioned into an incident state!',
        'View the incident in the dashboard for more information',
      );
    }
  }, [alarmEnabled, soundAlarm]);

  const userMenuOptions = [
    member && {
      label: 'Profile',
      value: createProfileUrl(),
    },
    {
      label: 'Logout',
      value: createLogOutUrl(),
    },
  ].filter((option) => !!option);

  return (
    <StyledHeader>
      <Nav>
        <UnorderedList>
          <li onClick={() => router.push('/')}>
            <Link href="/">
              {/* Looks like must use <button></button> here so that href is passed through from Link */}

              <Logo height={40} />
            </Link>
          </li>
          {!isLoading &&
            (user ? (
              <>
                <Can
                  perform="trips:list"
                  yes={() => (
                    <>
                      <HeaderButtonContainer
                        selected={
                          window.location.href.split('/')[3] === '' ||
                          window.location.href.split('/')[3] === 'trips'
                        }
                      >
                        <Link href="/">Dashboard</Link>
                      </HeaderButtonContainer>
                    </>
                  )}
                />
                <Can
                  perform="trips:history"
                  yes={() => (
                    <>
                      <HeaderButtonContainer
                        selected={
                          window.location.href.split('/')[3] === 'history'
                        }
                      >
                        <Link href="/history">History</Link>
                      </HeaderButtonContainer>
                    </>
                  )}
                />
                <Can
                  perform="groups:list"
                  yes={() => (
                    <>
                      <HeaderButtonContainer
                        selected={
                          window.location.href.split('/')[3] === 'groups'
                        }
                      >
                        <Link href="/groups">Groups</Link>
                      </HeaderButtonContainer>
                    </>
                  )}
                />
                <Can
                  perform="users:list"
                  yes={() => (
                    <HeaderButtonContainer
                      selected={window.location.href.split('/')[3] === 'admin'}
                    >
                      <Link href="/admin">Admin</Link>
                    </HeaderButtonContainer>
                  )}
                />

                <HeaderButtonContainer>
                  {member?.firstName && (
                    <NavMenuDropdown
                      label={`${toProperCase(member?.firstName)}`}
                      options={userMenuOptions}
                    />
                  )}

                  {!member && user?.email && (
                    <NavMenuDropdown
                      label={user?.email}
                      options={userMenuOptions}
                    />
                  )}
                </HeaderButtonContainer>

                <Box
                  display="flex"
                  alignItems="center"
                  marginLeft="auto"
                  mr="2"
                  minWidth="150px"
                  lineHeight="normal"
                >
                  <Box flex="1 0 90px">Audio alarm</Box>
                  <Box>
                    <CheckSwitch
                      action={handleToggleEnableSound}
                      isActive={alarmEnabled}
                    />
                  </Box>
                  {alarmEnabled && soundAlarm && (
                    <SoundPlayer url="/SAR-Alarm.mp3" />
                  )}

                  <Can
                    perform="trips:create"
                    yes={() => (
                      <Button
                        ml="2"
                        size="small"
                        maxWidth="140px"
                        wide
                        onClick={handleNewLogOnBtnClick}
                        variant="primary"
                      >
                        Log new trip
                      </Button>
                    )}
                  />
                </Box>
              </>
            ) : (
              <HeaderButtonContainer>
                <a href={createLoginUrl()}>Login</a>
              </HeaderButtonContainer>
            ))}
        </UnorderedList>
      </Nav>
    </StyledHeader>
  );
};

export default Header;
