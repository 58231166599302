import { DateTime } from 'luxon';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomerProfileFormSchema } from '../interfaces/customer-profile';
import { Trip, GetTripDetailsFormSchema } from '../interfaces/trip';
import { TripContactSchema } from '../interfaces/trip-contact';
import { WatercraftFormSchema } from '../interfaces/watercraft';
import { validateWaypointsForUpdateRequest } from '../lib/utils';
import TripContext from './TripContext';
import { buildWaypointsFromTrip } from './utils';

const DEFAULT_WAYPOINTS = [];
const DEFAULT_TRIP_DETAILS: Partial<Trip> = {
  timeType: 'return',
};
const DEFAULT_START_END_DETAILS = {};
const DEFAULT_WATERCRAFT = undefined;
const DEFAULT_CUSTOMER_PROFILE = undefined;
const DEFAULT_EMERGENCY_CONTACTS = [];

const CreateTripProvider = ({ children }) => {
  const [waypoints, setWaypoints] =
    useState<Trip['waypoints']>(DEFAULT_WAYPOINTS);

  const [notes, setNotes] = useState('');

  const [watercraft, setWatercraft] =
    useState<Partial<Trip['watercraft']>>(DEFAULT_WATERCRAFT);

  const [customerProfile, setCustomerProfile] = useState<
    Partial<Trip['owner']>
  >(DEFAULT_CUSTOMER_PROFILE);

  const [emergencyContacts, setEmergencyContacts] = useState<Trip['contacts']>(
    DEFAULT_EMERGENCY_CONTACTS,
  );

  const resetTrip = async () => {
    setWaypoints(DEFAULT_WAYPOINTS);
    setWatercraft(DEFAULT_WATERCRAFT);
    setCustomerProfile(DEFAULT_CUSTOMER_PROFILE);
    setEmergencyContacts(DEFAULT_EMERGENCY_CONTACTS);
  };

  const [tripDetails, setTripDetails] =
    useState<
      Partial<Pick<Trip, 'timeType' | 'isDiving' | 'numberOnBoard' | 'endTime'>>
    >(DEFAULT_TRIP_DETAILS);

  const [startEndDetails, setStartEndDetails] = useState<
    Partial<
      Pick<
        Trip,
        | 'startPointLatLon'
        | 'startPointName'
        | 'endPointLatLon'
        | 'endPointName'
      >
    >
  >(DEFAULT_START_END_DETAILS);
  const [sectionValidation, setSectionValidation] = useState({
    tripDetails: false,
    customerProfile: false,
    watercraft: false,
    waypoints: false,
    emergencyContacts: false,
  });

  const isValid = Object.values(sectionValidation).every((value) => value);

  // The trip object MUST be memoized - it is used to render components and trigger effects. In addition, the dependency array should
  // only include items of state internally so we can control when it is recreated
  const trip = useMemo(() => {
    const transformedWatercraft = WatercraftFormSchema.cast(watercraft, {
      assert: false,
    });

    // @ts-ignore
    delete transformedWatercraft.radioOptionsItems;

    // A start time of a plan is the time of creation, so lets add it
    const startTime = DateTime.now();
    const startTimeTimezone = startTime.toFormat('z');
    const startTimeUtcOffset = +startTime.toFormat('Z');

    // @ts-ignore No idea if the end Date is going to be a string or date
    let endTimeDateTime;
    if (typeof tripDetails?.endTime === 'string') {
      endTimeDateTime = DateTime.fromISO(tripDetails?.endTime);
    } else if ((tripDetails?.endTime as any) instanceof Date) {
      endTimeDateTime = DateTime.fromJSDate(tripDetails?.endTime);
    } else {
      endTimeDateTime = DateTime.now();
    }

    const endTimeTimezone = endTimeDateTime.toFormat('z');
    const endTimeUtcOffset = +endTimeDateTime.toFormat('Z');

    return {
      //  Defaults to make typescript happy
      startPointLatLon: {
        lat: 0,
        lon: 0,
      },

      endPointLatLon: {
        lat: 0,
        lon: 0,
      },

      ...GetTripDetailsFormSchema().cast(tripDetails, {
        assert: false,
      }),
      ...startEndDetails,
      id: 0,
      startTime: startTime.toJSDate(),
      startTimeTimezone,
      startTimeUtcOffset,
      endTimeTimezone,
      endTimeUtcOffset,
      waypoints,
      endTime: endTimeDateTime,
      owner: customerProfile as Trip['owner'],
      contacts: emergencyContacts,
      watercraft: transformedWatercraft,
    } as Trip;
  }, [
    tripDetails,
    startEndDetails,
    waypoints,
    customerProfile,
    emergencyContacts,
    watercraft,
  ]);

  // BuildWaypointsFromTrip MUST also be memoised
  const { allWaypoints, startWaypoint, endWaypoint } = useMemo(() => {
    return buildWaypointsFromTrip(trip);
  }, [trip]);

  const handleUpdateTripDetails = (details: Trip) => {
    setTripDetails(details);
  };

  const handleUpdateEmergencyContacts = useCallback(
    (contacts: Trip['contacts']) => {
      setEmergencyContacts(contacts);
    },
    [],
  );

  const handleUpdateWaypoints = (waypoints: Trip['waypoints']) => {
    // Extract the start and end, and set as properties on the trip. Then remove them from the list of waypoints and set into state
    const start = waypoints.find((waypoint) => waypoint.id === 'START');
    const end = waypoints.find((waypoint) => waypoint.id === 'END');

    // Find the waypoint without an ID. Because this is a new waypoint, create a random id which will be used to identify it
    const newWaypoint = waypoints.find((waypoint) => !waypoint.id);

    if (newWaypoint) {
      newWaypoint.id = Math.random().toString(36).substring(7);
    }

    const newWaypoints = waypoints.filter(
      (waypoint) => waypoint.id !== 'START' && waypoint.id !== 'END',
    );

    setWaypoints(newWaypoints);

    setStartEndDetails({
      startPointName: start?.name,
      startPointLatLon: start?.latLon,
      endPointName: end?.name,
      endPointLatLon: end?.latLon,
    });
  };

  useEffect(() => {
    const runValidation = async () => {
      try {
        const customerProfileValid = await CustomerProfileFormSchema.isValid(
          customerProfile,
        );

        const tripDetailsValid = await GetTripDetailsFormSchema().isValid(
          tripDetails,
        );

        const watercraftValid = await WatercraftFormSchema.isValid(watercraft);

        const allEmergencyContactsValidPromises = emergencyContacts.map(
          async (contact) => await TripContactSchema.isValid(contact),
        );

        const allEmergencyContactsValid = await Promise.all(
          allEmergencyContactsValidPromises,
        );

        let emergencyContactsValid = allEmergencyContactsValid.every(
          (value) => value,
        );

        let waypointsValid = false;
        try {
          validateWaypointsForUpdateRequest(allWaypoints, trip.timeType);

          if (
            startWaypoint.latLon.lat &&
            startWaypoint.latLon.lon &&
            endWaypoint.latLon.lat &&
            endWaypoint.latLon.lon
          ) {
            waypointsValid = true;
          }
        } catch (e) {
          waypointsValid = false;
        }

        setSectionValidation({
          customerProfile: customerProfileValid,
          tripDetails: tripDetailsValid,
          watercraft: watercraftValid,
          waypoints: waypointsValid,
          emergencyContacts: emergencyContactsValid,
        });
      } catch (e) {}
    };

    runValidation();
  }, [customerProfile, tripDetails, watercraft, allWaypoints, trip?.timeType]);

  return (
    <TripContext.Provider
      value={{
        trip,
        notes,
        tripIsValid: isValid,
        allWaypoints,
        startWaypoint,
        endWaypoint,
        tripSectionValidation: sectionValidation,
        refreshTrip: async () => {
          return trip as Trip;
        },
        updateNotes: setNotes,
        updateCustomerProfile: setCustomerProfile,
        updateTripDetails: handleUpdateTripDetails,
        updateWatercraftDetails: setWatercraft,
        updateWaypoints: handleUpdateWaypoints,
        updateEmergencyContacts: handleUpdateEmergencyContacts,
        resetTrip,
      }}
    >
      {children}
    </TripContext.Provider>
  );
};

export const withNewTripContext = (Component: FC) => () =>
  (
    <CreateTripProvider>
      <Component />
    </CreateTripProvider>
  );

export default CreateTripProvider;
