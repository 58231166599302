import { UserProfile, useUser } from '@auth0/nextjs-auth0';
import { useCallback } from 'react';
import useSWR from 'swr';
import { PermissionGroups, PermissionLevel } from '../interfaces/group-role';
import { User } from '../interfaces/user';
import fetcher from '../lib/fetcher';
import { MemberGroupRoles } from '../services/group-roles';
import { PermissionGroupDetails } from '../interfaces/group-role';
import rules, { UserPermissions } from '../lib/authorization/roles';

const useLoloMember = (): {
  member: User;
  isLoading: boolean;
  error: Error;
  canAdminister: (groupId?: string) => boolean;
  check: (
    action: UserPermissions,
    data?: Record<string, unknown>,
    developerMode?: boolean,
  ) => boolean;
} => {
  const { user, isLoading: isUserLoading, error: userError } = useUser();

  // Note: we just get a minimal set of member data from the Auth0 user

  let member: User | undefined;

  try {
    member = transformAuth0User(user, isUserLoading, userError);
  } catch (e) {
    console.error('Error transforming user into member', e);
  }

  const {
    data: groupRolesData,
    isLoading: isGroupRolesLoading,
    error: groupRolesError,
  } = useSWR(member?.id ? `/api/group-roles` : null, fetcher);

  const error = userError || groupRolesError;
  const isLoading = isUserLoading || isGroupRolesLoading;

  const permissionGroups = transformIntoPermissionGroups(groupRolesData);

  const canAdminister = useCallback(
    (groupId?: string) => {
      if (member?.developerMode === true || member?.role === 'super admin') {
        return true;
      }
      if (!permissionGroups) {
        return false;
      }
      return !![
        ...(permissionGroups.groupAdmin ?? []),
        ...(permissionGroups.regionAdmin ?? []),
        ...(permissionGroups.emergencyServiceAdmin ?? []),
      ].find((administerableGroup) => administerableGroup.groupId === groupId);
    },
    [permissionGroups],
  );

  const check = useCallback(
    (
      action: UserPermissions,
      data?: Record<string, unknown>,
      developerMode?: boolean,
    ): boolean => {
      // Developer mode bypasses all permissions
      if (developerMode === true) {
        return true;
      }

      if (member?.role === 'super admin') {
        return checkByPermissionLevel('superAdmin', action);
      }

      if (!permissionGroups) {
        return false;
      }

      return !!Object.entries(permissionGroups).find(
        ([permissionLevel, details]) =>
          checkByPermissionLevel(permissionLevel, action, details, data),
      );
    },
    [member, permissionGroups],
  );

  return {
    member: groupRolesData
      ? {
          ...member,
          permissionGroups: permissionGroups,
        }
      : undefined,
    isLoading,
    error,
    canAdminister,
    check,
  };
};

/** Transforms Auth0 user data into the structure expected for a LOLO member */
function transformAuth0User(
  user: UserProfile,
  isLoading: boolean,
  error: Error | undefined,
): User | undefined {
  if (!user || isLoading || error) {
    return undefined;
  }

  const { entityStoreMemberID, developerMode, role } = user[
    'https://search-rescue.deckee.com/app_metadata'
  ] as {
    entityStoreMemberID?: string;
    developerMode?: boolean;
    role?: string;
  };

  // if (!entityStoreMemberID) {
  //   throw new Error('Entity store member ID is missing');
  // }

  return {
    id: entityStoreMemberID,
    firstName: (user.nickname as string) || '',
    lastName: (user.family_name as string) || '',
    email: user.email,
    role,
    developerMode,

    // The auth0 user doesn't have the hidden information available
    hidden: undefined,
  };
}

export function transformIntoPermissionGroups(
  data?: MemberGroupRoles,
): PermissionGroups | undefined {
  if (!data) {
    return undefined;
  }
  const output: PermissionGroups = {};
  data.groupRoles.forEach((groupRole) => {
    if (groupRole.role === 'admin') {
      switch (groupRole.group.type) {
        case 'region':
          populatePermissionGroup('regionAdmin', groupRole, output);
          break;
        case 'emergency_service':
          populatePermissionGroup('emergencyServiceAdmin', groupRole, output);
          break;
        default:
          populatePermissionGroup('groupAdmin', groupRole, output);
          break;
      }
    } else if (groupRole.role === 'member') {
      switch (groupRole.group.type) {
        case 'region':
          populatePermissionGroup('regionMember', groupRole, output);
          break;
        case 'emergency_service':
          populatePermissionGroup('emergencyServiceMember', groupRole, output);
          break;
        default:
          populatePermissionGroup('groupMember', groupRole, output);
          break;
      }
    }
  });
  return output;
}

function populatePermissionGroup(
  permissionLevel: PermissionLevel,
  groupRole: MemberGroupRoles['groupRoles'][0],
  output: PermissionGroups,
) {
  if (!output[permissionLevel]) {
    output[permissionLevel] = [];
  }
  output[permissionLevel].push({ groupId: groupRole.group.id });
  groupRole.group.region_bases?.forEach((regionBase) => {
    output[permissionLevel].push({
      groupId: regionBase.information_group.id,
    });
  });
}

function checkByPermissionLevel(
  permissionLevel: string,
  action: UserPermissions,
  details?: PermissionGroupDetails[],
  data?: Record<string, unknown>,
) {
  const permissions = rules[permissionLevel];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(details ?? [], data);
  }
  return false;
}

export default useLoloMember;
