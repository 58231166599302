import React, { ButtonHTMLAttributes, HTMLAttributes, useState } from 'react';
import styled from 'styled-components';

const DropdownButton = styled.button<ButtonHTMLAttributes<HTMLButtonElement>>`
  color: ${({ theme }) => theme.colors.black};
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.336332px;
`;

interface DropdownContentProps extends HTMLAttributes<HTMLElement> {
  showDropDown: boolean;
}
const DropdownContent = styled.div<DropdownContentProps>`
  display: ${({ showDropDown }) => (showDropDown ? 'block' : 'none')};
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.defaultRadius};
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  a {
    color: ${({ theme }) => theme.colors.black};
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  a:hover {
    background-color: #ddd;
  }
`;

const DropdownDiv = styled.div`
  position: relative;
  display: inline-block;
`;

const NavMenuDropdown = ({
  label,
  options,
}: {
  label: string;
  options: { value: string; label: string }[];
}): React.ReactElement => {
  const [clicked, setClicked] = useState(false);

  const onButtonClick = () => {
    setClicked(!clicked);
  };

  return (
    <DropdownDiv>
      <DropdownButton onClick={onButtonClick}>{label}</DropdownButton>
      <DropdownContent showDropDown={clicked}>
        {options.map((option) => (
          <a key={option.label} href={option.value}>
            {option.label}
          </a>
        ))}
      </DropdownContent>
    </DropdownDiv>
  );
};

export default NavMenuDropdown;
