// dynamic rule can be used like
// <Can
//   role={user.role}
//   perform="posts:edit"
//   data={{
//     userId: user.id,
//     postOwnerId: post.ownerId
//   }}
//   yes={() => (
//     <button className="btn btn-sm btn-default">
//       Edit Post
//     </button>
//   )}
// />

import { PermissionGroupDetails } from '../../interfaces/group-role';

export type UserPermissions =
  | 'groups:create'
  | 'groups:edit'
  | 'groups:list'
  | 'posts:edit'
  | 'trips:create'
  | 'trips:edit'
  | 'trips:history'
  | 'trips:list'
  | 'trips:search'
  | 'users:create'
  | 'users:list'
  | 'waypoints:create'
  | 'waypoints:edit'
  | 'waypoints:list';

interface Rules {
  [permissionLevel: string]: {
    static: UserPermissions[];
    dynamic?: {
      [action in UserPermissions]?: (
        permissionGroupDetails: PermissionGroupDetails[],
        data?: Record<string, unknown>,
      ) => boolean;
    };
    displayName: string;
  };
}

const onlyAllowInGroups = (
  permissionGroupDetails,
  data?: { groups: string[] },
) => {
  const groups = data?.groups;
  if (!groups) {
    console.error('No group data was passed into onlyAllowInGroups!');
    return false;
  }
  return !!permissionGroupDetails.find((permissionGroupDetail) =>
    groups.includes(permissionGroupDetail.groupId),
  );
};

const groupMember: Rules['groupMember'] = {
  displayName: 'Group member',
  static: [
    'groups:list',
    'trips:create',
    'trips:list',
    'trips:history',

    // Not tested yet
    'trips:search',
    'waypoints:create',
    'waypoints:edit',
    'waypoints:list',
  ],
  dynamic: {
    'trips:edit': onlyAllowInGroups,
  },
};

const groupAdmin: Rules['groupAdmin'] = {
  displayName: 'Group admin',
  static: [...groupMember.static, 'users:create', 'users:list'],
  dynamic: {
    ...groupMember.dynamic,
    'groups:edit': onlyAllowInGroups,
  },
};

const regionMember: Rules['regionMember'] = {
  ...groupMember,
  displayName: 'Region member',
};

const regionAdmin: Rules['regionAdmin'] = {
  ...groupAdmin,
  displayName: 'Region admin',
};

const emergencyServiceMember: Rules['emergencyServiceMember'] = {
  displayName: 'Emergency service member',

  static: ['groups:list', 'trips:edit', 'trips:history', 'trips:list'],
};

const emergencyServiceAdmin: Rules['emergencyServiceAdmin'] = {
  ...emergencyServiceMember,
  displayName: 'Emergency service admin',

  static: [...emergencyServiceMember.static, 'users:create', 'users:list'],
};

const superAdmin: Rules['superAdmin'] = {
  displayName: 'Super admin',
  static: [
    'groups:create',
    'groups:edit',
    'groups:list',
    'posts:edit',
    'trips:create',
    'trips:edit',
    'trips:history',
    'trips:list',
    'trips:search',
    'users:create',
    'users:list',
    'waypoints:create',
    'waypoints:edit',
    'waypoints:list',
  ],
};

const rules: Rules = {
  regionAdmin,
  regionMember,
  groupAdmin,
  groupMember,
  emergencyServiceAdmin,
  emergencyServiceMember,
  superAdmin,
};

export default rules;
