/* eslint-disable camelcase */
import * as Yup from 'yup';
import { Watercraft } from './watercraft';

export interface CustomerProfile {
  id?: number;
  deckeeMemberId?: string;
  fullName: string;
  title: string;
  email?: string;
  phoneNumber: string;
  phoneLandlineNumber?: string;
  watercrafts?: Watercraft[];
  hidden?: boolean;
}

export const CustomerProfileFormSchema = Yup.object().shape({
  fullName: Yup.string().required('Required'),
  phoneNumber: Yup.string().required('Required'),
  phoneLandlineNumber: Yup.string(),
});
