import { Trip } from '../interfaces/trip';
import { TripWaypoint } from '../interfaces/trip-waypoint';

export const validateWaypointsForUpdateRequest = (
  points: TripWaypoint[],
  tripType: Trip['timeType'],
) => {
  points.forEach((point) => {
    // Each points ordinal should be unique
    if (!!points.find((p) => p.ordinal === point.ordinal && point !== p)) {
      throw new Error('Two points have the same ordinal');
    }
  });

  // A return trip must have at least three points
  if (tripType === 'return' && points.length < 3) {
    throw new Error('Return trips must have at least three waypoints');
  }

  // A one way trip must have at least two points
  if (tripType === 'return' && points.length < 2) {
    throw new Error('One way trips must have at least two waypoints');
  }

  // A return trips first and last point must have the same lat lon
  if (
    tripType === 'return' &&
    points[0].latLon.lat !== points[points.length - 1].latLon.lat &&
    points[0].latLon.lon !== points[points.length - 1].latLon.lon
  ) {
    throw new Error(
      'Return trips first and last point must have the same lat lon',
    );
  }

  if (
    tripType === 'return' &&
    points[0].name !== points[points.length - 1].name
  ) {
    throw new Error('Return trips must have the same start and end point');
  }
};
