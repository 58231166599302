import * as Yup from 'yup';

// NOTE: Taken from the api-app-main generated code.
export const VesselTypeEnum = {
  Motorboat: 'motorboat',
  Sailboat: 'sailboat',
  PaddleCraft: 'paddle-craft',
  PersonalWatercraft: 'personal-watercraft',
} as const;

export type VesselTypeEnum = typeof VesselTypeEnum[keyof typeof VesselTypeEnum];

export interface Watercraft {
  id?: number;
  name: string;
  watercraft_type: VesselTypeEnum;
  registration?: string;
  make_model: string;
  call_sign: string;
  vessel_length: number;
  length_units: string;
  beam: number;
  beam_units: string;
  draft: number;
  draft_units: string;
  capacity: number;
  image: string;
  hidden: boolean;
  radioOptions: string;
  storedOnTrailer: boolean;
  hullColor: string;
  storageDescription: string;
  deckeeId?: string;
  trailerRegistration: string;
  owner?: {
    id: number;
    deckeeMemberId?: string;
    fullName: string;
    title: string;
    email?: string;
    phoneNumber: string;
    phoneLandlineNumber?: string;
    hidden?: boolean;
  };
}

export const WatercraftFormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  watercraft_type: Yup.string()
    .oneOf(['motorboat', 'sailboat', 'paddle-craft', 'personal-watercraft'])
    .required('Vessel type is required'),
  make_model: Yup.string().nullable(),

  registration: Yup.string().uppercase(),

  storedOnTrailer: Yup.boolean().nullable(),
  storageDescription: Yup.string().nullable(),
  trailerRegistration: Yup.string().nullable(),

  hullColor: Yup.string().nullable(),
  radioOptions: Yup.string().when('watercraft_type', {
    is: (type) => ['motorboat', 'sailboat'].includes(type),
    then: () => Yup.string().required('Radio equipment is required'),
  }),
  vessel_length: Yup.number()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable(),
  length_units: Yup.string().nullable(),
  beam: Yup.number()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable(),
  beam_units: Yup.string().nullable(),
  draft: Yup.number()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable(),
  draft_units: Yup.string().nullable(),
  capacity: Yup.number()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable(),
  call_sign: Yup.string().nullable(),
  image: Yup.string().nullable(),
  hidden: Yup.boolean().nullable(),
});
